/** Pinepointers 2: Kim **/

import React from "react";
import { preloadImages, scaleFullscreen, showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import VideoPlayer from "../../components/media/video-player"
import { gsap, Circ } from "gsap";

let globalState, data, preloadData;

class PinepointersPage2 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[2];
        preloadData = globalState.assets[3];

        //refs
        this.fullscreenBg = null;

    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        setTimeout(() => {
            //1. play video
            this.refs.bgVideoRef.play();

            //2. animate highlighter
            gsap.to(this.refs.highlighterWrapperRef, 1, { scale: 1, ease: Circ.easeOut, delay: 0.5 });

            //3. Animate in Badge
            gsap.to(this.refs.beautyBadgeRef, 0.75, { x: 0, y: 0, rotate: 0, ease: Circ.easeOut, delay: 1 });

        }, globalState.transitionDuration);


        //position text
        positionText(this.refs.text1Ref, "left", "bottom", globalState.textPosition.leftBottom);


        //show text
        gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

        //add base track
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2);

        //dim main base track
        globalState.baseAudioRef.updateVolume(1, 70);

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.badge,
            globalState.cdnUrl + preloadData.caption,
            globalState.cdnUrl + preloadData.highlight1, 
            globalState.cdnUrl + preloadData.highlight2, 
            globalState.cdnUrl + preloadData.background,
        ], this.preloadCallback);

        //Hls is added in gatsby-ssr.js
        /* eslint-disable */
        let videoStream = globalState.videoUrl + data.bgVideo;
        console.log("videoStream: " + videoStream);

        if (Hls.isSupported()) {
            var video = this.refs.bgVideoRef;
            var hls = new Hls();
            // bind them together
            hls.attachMedia(video);
            // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                console.log("video and hls.js are now bound together !");
                hls.loadSource(videoStream);
                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    console.log("manifest loaded, found " + data.levels.length + " quality level");
                });
            });
        }

        /*eslint-enable */

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);

        window.removeEventListener("resize", this.updateDimensions);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "left", "bottom", globalState.textPosition.leftBottom);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "left");

        }
    }

    isTransitioning = () => {
        this.refs.bgVideoRef.pause();
        //pause animations
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 724
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 724, false);

        let sx = window.innerWidth / 1288;
        let sy = window.innerHeight / 724;

        this.refs.playVideoBtnRef.style.left = 732 * Math.max(sx, sy) + 'px';
        this.refs.playVideoBtnRef.style.top = 427 * Math.max(sx, sy) + 'px';

    }

    openVideoPlayer = () => {
        this.refs.videoModalRef.classList.add("show");
        this.refs.videoPlayerRef.seekVideo(0);
        this.refs.videoPlayerRef.playVideo();

        //set audio tracks volume to 0 
        globalState.baseAudioRef.updateVolume(1, 0);
        globalState.baseAudioRef.updateVolume(2, 0);
    }

    closeVideoPlayer = () => {
        this.refs.videoModalRef.classList.remove("show");
        this.refs.videoPlayerRef.pauseVideo();

        //set audio tracks volume to normal
        globalState.baseAudioRef.updateVolume(1, 70);
        globalState.baseAudioRef.updateVolume(2, 100);
    }

    render() {

        return (

            <>

                <link rel="prefetch" href="/pinepointers/3" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/pinepointers/3" prevLocation="/pinepointers/1"
                    isTransitioning={this.isTransitioning} />
                {data && (
                <>
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Pinepointers" />

                    <div className="text-wrapper">
                        <div className="text text--left-bottom" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "8vw", marginBottom: "15vh" }} onClick={this.toggleTextDisplay} alt="Kim Kastle had a job at the mine, but that’s not what she’s remembered for. Kim was the latest hairstyle, the newest dance moves, the eyeshadow and attitude of a wider world. She didn’t need to know that Pine Point would one day shut down to know that she wasn’t there to stay. In the meantime, though, even the smallest town needs some glamour." />
                        </div>
                    </div>

                    <div className="fullscreen-bg pinepointers-bg" ref="fullscreenBgRef">
                        <video ref="bgVideoRef" poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} muted={true} playsInline className="video" style={{ width: '368px', height: '274px', top: '-14px', right: 0, position: 'absolute', zIndex: -1 }}>
                            {/* <source src={globalState.videoUrl + data.bgVideo} type="video/mp4" /> */}
                            <track kind="captions" srcLang="en" />
                        </video>
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background" />
                        <div className="highlighterWrapper" ref="highlighterWrapperRef" style={{ left: '27px', top: '236px', width: '171px', height: '27px' }}>
                            <img src={globalState.cdnUrl + data.highlight} alt="highlight" className="highlight" />
                        </div>
                        <img src={globalState.cdnUrl + data.badge} className="badge beautyBadge" ref="beautyBadgeRef" alt="The Beauty" style={{ left: '202px', top: '3px' }} />
                    </div>
                    <input type="image" ref="playVideoBtnRef" className="playVideoBtn" src={globalState.cdnUrl + data.playVideoBtn} onClick={this.openVideoPlayer} alt="Click to play music video" />
                </div>
                <div className="video-modal" ref="videoModalRef">
                    <div className="video-wrapper">
                        <button onClick={this.closeVideoPlayer} className="close" alt="Close" />
                        <img src={globalState.cdnUrl + data.caption} alt="'Love in the Big City', 1988" className="caption" style={{ top: '15px', left: '15px' }} />
                        <VideoPlayer ref="videoPlayerRef" globalState={globalState} file={globalState.videoUrl + data.musicVideo} width={937} height={627} />
                    </div>
                </div>
                </>
                )}

            </>
        );
    }


};

export default PinepointersPage2

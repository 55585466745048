import React from "react"
import { fmtMSS } from "../../helpers"
import { gsap, Draggable } from "gsap/all";



let timeline, timelineProgress, drag, state, props, timelineInterval, pauseInterval = false;

class VideoControls extends React.Component {

    componentDidMount() {

        //Register draggable plugin
        gsap.registerPlugin( Draggable ); 

        timeline = this.refs.timelineRef;
        timelineProgress = this.refs.timelineProgressRef;
        drag = this.refs.timelineDragRef;

        // Make the timeline draggable
        
        Draggable.create(drag, {
            type: 'x',
            trigger: timeline,
            bounds: timeline,
            onPress: function (e) {
                pauseInterval = true;
                gsap.set(this.target, {
                    x: this.pointerX - timeline.getBoundingClientRect().left
                });
                this.update();
                let progress = this.x / timeline.offsetWidth * timeline.offsetWidth;
                let newTime = this.x / this.maxX * state.duration;
                props.seekVideo(newTime, false);
                gsap.set(timelineProgress, {
                    width: progress + "px"
                });
            },
            onDrag: function () {
                pauseInterval = true;
                let newTime = this.x / this.maxX * state.duration;
                props.seekVideo(newTime, false);
                
                let progress = this.x / timeline.offsetWidth * timeline.offsetWidth;
                
                gsap.set(timelineProgress, {
                    width: progress + "px"
                });
            },
            onRelease: function (e) {
                e.preventDefault();
                pauseInterval = false;
            }
        });
        

        timelineInterval = setInterval(() => {
            this.vidUpdate();
        }, 100);
        
    }

    componentWillUnmount(){
        clearInterval(timelineInterval);
    }

    togglePlayPause = () => {
        this.props.state.isPlaying ? this.props.pauseVideo() : this.props.playVideo();
    }

    

    // Sync the timeline with the video duration
    vidUpdate = () => {
        if(!pauseInterval){
            let progress = this.props.state.time / this.props.state.duration * timeline.offsetWidth;
            
            gsap.set(timelineProgress, {
                //scaleX: (this.props.state.time / this.props.state.duration).toFixed(5)
                width: progress + "px"
            });
            
            gsap.set(drag, {
                x: (this.props.state.time / this.props.state.duration * timeline.offsetWidth)
            });
        }
    }



    render() {

        state = this.props.state;
        props = this.props;
        
        return (

            <div className="video-controls">
                <button className={this.props.state.isPlaying ? "play-pause is-playing" : "play-pause"} alt="Play" onClick={this.togglePlayPause} ref="playPauseRef" />
                <input type="text" className="time" readOnly value={fmtMSS(parseInt(this.props.state.time))} />
                <input type="text" className="duration" readOnly value={fmtMSS(parseInt(this.props.state.duration))} />

                <div className="timeline" ref="timelineRef">
                    <input type="image" className="timeline__progress" ref="timelineProgressRef" alt="" />
                    <button className="timeline__drag" ref="timelineDragRef" />
                </div>
            </div>


        )
    }


}


export default VideoControls